import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Container, Typography, Divider, Alert } from '@mui/material';
// routes
import { PATH_AUTH } from 'routes/paths';
// hooks
import useAuth from 'hooks/useAuth';
// components
import Page from 'core/components/Page';
import { MHidden } from 'core/components/@material-extend';
import { LoginForm } from 'components/authentication/login';

import { Icon } from '@iconify/react';
import infoFill from '@iconify/icons-eva/info-fill';
import AuthLayout from 'layouts/AuthLayout';
import InfoIcon from '@mui/icons-material/Info';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 490,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { method } = useAuth();

  return (
    <RootStyle title="Giriş Yap">
      <AuthLayout children={undefined} />
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Merhaba, Hoş geldiniz!
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                Giriş Yap
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                E-posta ve şifrenizi giriniz.
              </Typography>
              <Box mt={2} sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <Alert sx={{ fontSize: 13 }} severity="info" icon={<InfoIcon />}>
                  Henüz üye olmadıysanız isbank.com.tr uzantılı e-posta adresinizle kayıt
                  olabilirsiniz.
                </Alert>
              </Box>
            </Box>
          </Stack>

          <LoginForm />

          <Divider sx={{ my: 4 }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              VEYA
            </Typography>
          </Divider>

          <Typography variant="subtitle1" align="center" sx={{ color: 'text.primary' }}>
            Hesabınız yok mu? &nbsp;
            <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
              Hesap Oluşturun
            </Link>
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
