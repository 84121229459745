// hooks
import useAuth from 'hooks/useAuth';
//
import { MAvatar } from '../components/@material-extend';
import { MAvatarProps } from '../components/@material-extend/MAvatar';
import createAvatar from 'utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: MAvatarProps) {
  const { user } = useAuth();

  return (
    <MAvatar alt={user?.full_name} color={createAvatar(user?.displayName).color} {...other}>
      {createAvatar(user?.full_name).name}
    </MAvatar>
  );
}
