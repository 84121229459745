import axios from 'axios';

// ----------------------------------------------------------------------

const PROD_API = 'https://api.collecthink.com';
const STAGE_API = 'https://test-api.collecthink.com';

const { origin } = window.location;

const IS_ORIGIN_TEST = origin.includes('test') || origin.includes('localhost');

const axiosInstance = axios.create({
  baseURL: `${IS_ORIGIN_TEST ? STAGE_API : PROD_API}/api/v1`,
  headers: { 'x-api-key': 'apiKey' }
});

axiosInstance.interceptors.request.use(
  async (config) => {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    };
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(`axios status: ${error.response.status}`);
    if ([401, 403].includes(error.response.status)) {
      localStorage.removeItem('user');
      localStorage.removeItem('authToken');
      // window.location.replace('/auth/login');
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;
