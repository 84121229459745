import { Link as ScrollLink } from 'react-scroll';
import { useLocation, Outlet } from 'react-router-dom';
// material
import { Box, Container } from '@mui/material';

import MainNavbar from './MainNavbar';
import useAuth from 'hooks/useAuth';
import { PATH_PAGE, PATH_DASHBOARD } from 'routes/paths';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { user } = useAuth();
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  const isApplicationPage = Object.values(PATH_PAGE.application).includes(pathname);
  const isAdmin = user?.roles.includes('ADMIN');

  if (!isHome && isApplicationPage) {
    if (!isAdmin && !isApplicationPage) {
      window.location.replace('/');
    }

    if (isAdmin && isApplicationPage) {
      window.location.replace(PATH_DASHBOARD.root);
    }
  }

  return (
    <>
      <MainNavbar />
      <div>
        <Outlet />
      </div>

      <Box
        sx={{
          py: 5,
          textAlign: 'center',
          position: 'relative',
          bgcolor: 'background.default'
        }}
      >
        <Container maxWidth="lg">
          <ScrollLink to="move_top" spy smooth>
            {/* <Logo sx={{ mb: 1, mx: 'auto', cursor: 'pointer' }} /> */}
          </ScrollLink>

          {/* <Typography variant="caption" component="p">
              © All rights reserved
              <br /> made by &nbsp;
              <Link href="https://minimals.cc/">minimals.cc</Link>
            </Typography> */}
        </Container>
      </Box>
    </>
  );
}
