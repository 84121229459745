import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';

// material
import {
  Link,
  Stack,
  Alert,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from 'routes/paths';
// hooks
import useAuth from 'hooks/useAuth';
import useIsMountedRef from 'hooks/useIsMountedRef';
//
import { MIconButton } from 'core/components/@material-extend';
import { initial, toString } from 'lodash';

// ----------------------------------------------------------------------
type InitialValues = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};
export default function LoginForm() {
  const { login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [remember, setRemember] = useState(false);

  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('E-posta adresi doğru formatta olmalıdır.')
      .required('E-posta alanı zorunludur.'),
    password: Yup.string().required('Şifre alanı zorunludur.')
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      email: toString(localStorage.getItem('username')),
      password: toString(localStorage.getItem('password')),
      remember: localStorage.getItem('checkbox') == 'true' ? true : false
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        await login(values.email.toLowerCase(), values.password);
        if (remember) {
          localStorage.setItem('username', values.email);
          localStorage.setItem('password', values.password);
          localStorage.setItem('checkbox', 'true');
        } else {
          localStorage.setItem('username', '');
          localStorage.setItem('password', '');
          localStorage.setItem('checkbox', 'false');
        }
        enqueueSnackbar('Giriş başarılı', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error: any) {
        console.log(error);
        if (error.status === 403 || !!error.email) {
          navigate(PATH_AUTH.verify, { state: { email: error.email } });
        }
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: 'Giriş Bilgilerinizi Kontrol Ediniz' });
        }
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  // useEffect(() => {
  //   if (localStorage.checkbox && localStorage.checkbox !== '') {
  //     setRemember(true);
  //     setMail(localStorage.username);
  //     setPassword(localStorage.password);
  //   } else {
  //     setRemember(false);
  //     setMail('');
  //     setPassword('');
  //   }
  // }, []);

  // console.log('test', localStorage.getItem('isRemember'));
  // const lsRememberMe = (mail: any, pass: any) => {
  //   setRemember(!remember);
  //   const item = {
  //     mail: mail,
  //     password: pass
  //   };
  //   if (!remember) {
  //     localStorage.setItem('remember', JSON.stringify(item));
  //   }
  //   localStorage.setItem('isRemember', remember.toString());
  // };
  // const itemStr = JSON.parse(localStorage.getItem('remember') || '{}');

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
          {/* {itemStr?.mail != undefined && ( */}
          <>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="E-posta adresi"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Şifre"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </>
          {/* )} */}
          {/* {itemStr?.mail == undefined && (
            <>
              <TextField
                fullWidth
                autoComplete="username"
                type="email"
                label="E-posta adresi"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
              <TextField
                fullWidth
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                label="Şifre"
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            </>
          )} */}
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                //{...getFieldProps('remember')}
                //defaultChecked={localStorage.getItem('isRemember') === 'true'}
                // checked={remember}
                // onClick={() => {
                //   lsRememberMe(values.email, values.password);
                // }}
                {...formik.getFieldProps('remember')}
                onChange={() => setRemember(!formik.values.remember)}
                defaultChecked={formik.values.remember}
              />
            }
            label="Beni Hatırla"
          />

          <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.resetPassword}>
            Şifremi Unuttum
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{
            backgroundColor: '#45A9FB'
          }}
        >
          Giriş Yap
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
