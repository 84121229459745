import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, AppBar, Toolbar, Container, Stack, Typography } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// components
//import Label from '../../components/Label';
//import { MHidden } from '../../components/@material-extend';
//
//import MenuDesktop from './MenuDesktop';
//import MenuMobile from './MenuMobile';
//import navConfig from './MenuConfig';
import AccountPopover from 'layouts/dashboard/AccountPopover';
import useAuth from 'hooks/useAuth';
//import { PATH_AUTH } from 'routes/paths';
import Logo from 'components/Logo';
import { detect } from 'detect-browser';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export type MenuItemProps = {
  title: string;
  path: string;
  icon?: JSX.Element;
  to?: string;
  children?: {
    subheader: string;
    items: {
      title: string;
      path: string;
    }[];
  }[];
};

export type MenuProps = {
  isOffset: boolean;
  isHome: boolean;
  navConfig: MenuItemProps[];
};

export default function MainNavbar() {
  const isOffset = useOffSetTop(100);
  //const { pathname } = useLocation();
  const { user } = useAuth();
  //const isHome = pathname === '/';
  const detectBrowser = (): boolean => {
    const browser = detect();
    switch (browser && browser.name) {
      case 'chrome':
      case 'firefox':
      case 'safari':
      case 'edge':
      case 'edge-chromium':
      case 'ios':
      case 'android':
        return false;
      default:
        return true;
    }
  };
  const browserFlag: boolean = detectBrowser();
  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      {browserFlag && (
        <Container
          sx={{
            backgroundColor: 'white',
            textAlign: 'center',
            width: '100%',
            height: 60,
            py: 1.5,
            display: 'flex'
          }}
        >
          <img src="static/brand/edge_logo.png" alt="Edge Logo" height={'100%'} />
          <Typography
            variant="subtitle1"
            sx={{
              mx: 3,
              color: '#2b5691',
              alignItems: 'center',
              textAlign: 'center',
              alignSelf: 'center',
              fontSize: '18px'
            }}
          >
            Platformun her aşamasında Microsoft Edge kullanmayı unutmayın. Platform Internet
            Explorer ile uyumlu değildir.
          </Typography>
        </Container>
      )}
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: '90px',
                width: '200px',
                '@media (max-width:900px)': {
                  height: '70px',
                  width: '80px',
                  padding: '1px'
                },
                '@media (max-width:500px)': {
                  height: '70px',
                  width: '80px',
                  padding: '0px'
                }
              }}
            />
          </RouterLink>
          <Box sx={{ flexGrow: 1 }} />
          <Button
            href="/kullanim-kilavuzu"
            target="_blank"
            variant="text"
            sx={{ mr: 2, color: 'text.secondary', fontWeight: 500 }}
          >
            Kullanım Kılavuzu
          </Button>
          {user ? (
            <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
              <AccountPopover />
            </Stack>
          ) : (
            <Button variant="contained" href="/auth/login">
              Giriş Yap
            </Button>
          )}
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
