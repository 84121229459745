// material
import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

const ImageStyle = styled('div')(({ theme }) => ({
  display: 'flex'
}));

export default function Logo({ sx }: BoxProps) {
  return (
    <Box
      sx={{
        '@media (max-width:500px)': {
          width: '100%'
        },
        ...sx
      }}
    >
      {
        <ImageStyle sx={{ ...sx }}>
          <img src={'/static/app-logo/app_logo.png'} alt="App Logo" />
        </ImageStyle>
      }
    </Box>
  );
}
